import React from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import Input from '../CommonComp/Input';

function DownloadBackUps({tableData=[], handleTableItemClick=()=>{}, selectedRow}) {

 const isItemInTheSelection=(allItems,row)=>{
  let index=allItems?.findIndex((item=>item?.shoot_id==row?.shoot_id));
  if(index>=0)return true;
  else return false;
 }
  return (
    <div
      className="p-0 border-1"
      style={{flex: 1}}
      tabIndex={0}
    >
      <TableVirtuoso
        style={{ height: "100%", border: "1px solid lightgray" }}
        totalCount={tableData.length}
        components={{
          Table: ({ style, ...props }) => {
            return (
              <table
                {...props}
                style={{
                  ...style,
                  width: "100%",
                  tableLayout: "fixed",
                  borderCollapse: "collapse",
                  borderSpacing: 0
                }}
              />
            );
          },
          TableHead: (props) =>{
            return (
              <thead 
              {...props}
                style={{
                  ...props?.style,
                  zIndex: 0
                }}
              >
                {props?.children}
              </thead>
            );
          },
          TableRow: (props) => {
            const index = props["data-index"];
            const row = tableData[index];
            return (
              <tr 
              {...props}
              onMouseDown={(e) => {
                handleTableItemClick({...row, currentIndex: index})
                console.log("calling")
              }}
              style={{
                ...props?.style,
                background:  isItemInTheSelection(selectedRow,row) ? "#b6b6b6a1" : "none",
                cursor: 'pointer'
              }}
              >
                <td style={{ background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white',  padding: 4  }}>{index+1}</td>
                {/* <td style={{  fontSize: 12,  padding: 4 ,textAlign: "center" }}><div className='truncate-string'>{row?.image_name}</div></td> */}
                
                <td style={{  fontSize: 12,  padding: 4,textAlign: "center"  }}>{row?.shoot_id}</td>
                {/* <td style={{  fontSize: 12,  padding: 4,textAlign: "center"  }}>
                  <Input
                 labelPlacement = "center"
                  type="checkbox"
                  id={row?.shoot_id}
                  checked={row?.isChecked}
                  onChange={(e) => {onCheckboxClick(row?.shoot_id, index);console.log(row?.shoot_id,e)}}
                  mainContainerProps={{
                    style:{
                      minHeight: 'auto'
                    }
                  }}    
              />
             
              </td> */}
              </tr>
            );
          }
        }}
        fixedHeaderContent={(index, user) => (
          <tr style={{ background: "lightgray", margin: 0 }}>
            <th style={{ width: 20, background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white', padding: 4 }}>Sr No.</th>
            <th style={{ width: 80, background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white', padding: 4}}>Shoot ID</th>
            {/* <th style={{ width: 80, background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "left", color: 'white', padding: 4}}>Action</th> */}

          </tr>
        )}
      />
    </div>
  )
}

export default DownloadBackUps