import React, { useEffect, useState } from "react"
import DynamicScrollContent from "../HOC/DynamicScrollContent"

function SelectedResultBox({selectedResults, setSelectedResults, isMaximized=false, setSelectedResultFocus=()=>{}}, ref) {
  const [parentHeight, setParentHeight] = useState(414)
  const handleClick = (e, index) => {
    if (e.detail % 2 !== 0) {
      let selectedList = document.getElementsByClassName("selected-list")
      if (selectedList?.length) {
        for (var i = 0; i < selectedList?.length; i++) {
          selectedList[i].classList.remove("focused-li")
        }
      }
      if (e.target.tagName === "LI") {
        e.target.classList.add("focused-li")
      } else if (e.target.tagName === "DIV") {
        // Add the class to the clicked <li> element's parent
        e.target.parentNode.classList.add("focused-li")
      } else if (e.target.tagName === "BUTTON") {
        e.target.parentNode.classList.add("focused-li")
      }
    } else if (e.detail % 2 === 0) {
      handleRemove(
        index,
        index === 0
          ? "ul_li_child" + index
          : index === selectedResults?.length - 1
          ? "ul_li_child" + (index - 1)
          : "ul_li_child" + (index + 1)
      )
    }
    document.activeElement.blur();
    setSelectedResultFocus(true);
  }

  const handleRemove = (index, id) => {
    let copyArr = [...selectedResults]
    copyArr.splice(index, 1)
    setSelectedResults(copyArr)
    if (id && selectedResults?.length) {
      let ele = document.getElementById(id)
      if (ele) {
        ele?.focus()
      }
    }
  }

  // useEffect(()=>{
  //   let element = document.getElementById(`keyword-list-container`)
  //   if(element){
  //     if(!isMaximized){
  //       setParentHeight(element?.getBoundingClientRect()?.width - 170)
  //     }else{
  //       setParentHeight(element?.getBoundingClientRect()?.width - 247)
  //     }
  //   }
  // })


  return (
    <div className="col-4 selected-keyword d-flex flex-column">
      <DynamicScrollContent outerContainerStyle={{flex: 1}} ref={ref} internalContainerProps={{
        className: 'border-1 selected-keyword-container'
      }}>
      {/* <ul className="no-bullets  p-1"> */}
        {selectedResults?.map((el, index) => {
          return (
            <div
              key={el}
              id={"ul_li_child" + index}
              className="size-11 selected-list srb-item"
              style={{ display: "flex", cursor: 'pointer', fontWeight: 600 }}
              onClick={(e) => {
                handleClick(e, index)
              }}
              onMouseDown={function (e) {
                e.preventDefault()
              }}
            >
              <div
                style={{
                  flex: 1,
                  wordBreak: "break-word",
                  paddingRight: 10,
                  display: "flex",
                  alignItems: "center",
                  padding: '1px 6px', lineHeight: 1.4,
                }}
                className="px-1 truncate-string-Family"
              >
                {el}
              </div>
            </div>
          )
        })}
      {/* </ul> */}
      </DynamicScrollContent>
    </div>
  )
}

export default React.forwardRef(SelectedResultBox);
