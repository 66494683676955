import React, { useState, useRef, useEffect } from "react"
import { getVisibleKeywordListingNoRedux } from "../../services/keywordsApis"
import { findObjectIndexInArray, getArrayOfObjJoinToStringForKey } from "../../lib/helper"
import Input from "../CommonComp/Input"
import Select from "../CommonComp/Select"

function SearchSection({ setActualKeywords=()=>{}, selectedResults=[], setSelectedResults=()=>{},
  categoriesData=[], resetData=false, setResetData=()=>{}, selectedCategory=null, handleCategoryChange=()=>{},
  isMaximized, parentDimensions 
}) {
    const [query, setQuery] = useState("")
    const [results, setResults] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(null)
    const resultsContainerRef = useRef(null)
    const inputRef = useRef(null)
    
    useEffect(()=>{
        if(resetData){
            setResults([])
            setSelectedIndex(null)
            setSelectedResults([])
            setActualKeywords([])
            setQuery("")
            setResetData(false)
        }
    },[resetData])
  
    useEffect(() => {
      const handleKeydown = (e) => {
        if (!(document.activeElement.id === "search-results")) {
          if (e.key === "Tab" && document.activeElement.id === "search-input") {
            // Hide the results popup or perform other actions if needed
            // e.preventDefault()
            setSelectedIndex(0)
          } else if (e.key === "Enter") {
            if (document.activeElement.id === "search-input") {
              handleResultSelection(selectedIndex)
              if (inputRef) {
                inputRef?.current?.focus()
                setTimeout(function () {
                  inputRef?.current?.select()
                }, 10)
              }
            }
          }
          return // Exit early if the active element is not within the select-results
        }
        if (e.key === "ArrowUp" && selectedIndex > 0) {
          const container = document.getElementById("tablebody-search-container")
          const element = document.getElementById(
            results[selectedIndex - 1]?.vid +
              results[selectedIndex - 1]?.visible_keyword +
              (selectedIndex - 1)
          )
          // element.scrollIntoView(false);
          // Check if the element is at the top of the container
          if (
            selectedIndex * element.clientHeight - element.clientHeight <=
            container.scrollTop
          ) {
            container.scrollTop -= element.clientHeight
          }
          setSelectedIndex(selectedIndex - 1)
          scrollContainerToSelected()
        } else if (
          (e.key === "ArrowDown" ||
            e.key === "ArrowRight" ||
            e.key === "ArrowLeft") &&
          selectedIndex < results.length - 1
        ) {
          const element = document.getElementById(
            results[selectedIndex + 1]?.vid +
              results[selectedIndex + 1]?.visible_keyword +
              (selectedIndex + 1)
          )
          // element.scrollIntoView(false);
          const container = document.getElementById("tablebody-search-container")
          if (
            selectedIndex * element.clientHeight + element.clientHeight >=
            container.scrollTop + container.clientHeight
          ) {
            container.scrollTop += element.clientHeight
          }
  
          setSelectedIndex(selectedIndex + 1)
          scrollContainerToSelected()
        } else if (e.key === "Enter" && selectedIndex !== null) {
          handleResultSelection(selectedIndex)
          if (inputRef) {
            inputRef?.current?.focus()
            setTimeout(function () {
              inputRef?.current?.select()
            }, 10)
          }
        } else if (e.key === "Tab") {
          // Hide the results popup or perform other actions if needed
          // e.preventDefault()
          // setSelectedIndex(0)
          setSelectedIndex(null)
        } else if (e.key === "Escape") {
          setResults([])
          setSelectedIndex(null)
          // Hide the results popup or perform other actions if needed
        }
      }
  
      window.addEventListener("keydown", handleKeydown)
  
      return () => {
        window.removeEventListener("keydown", handleKeydown)
      }
    }, [results, selectedIndex, selectedResults])
  
    async function searchKeywords(e) {
      let value = inputRef.current.value;
      setQuery(value)
      if (value.length > 1) {
        let data = await getVisibleKeywordListingNoRedux({
          search: value,
          page: "1",
          limit: "20",
        })
        setResults(data)
      } else {
        setResults([])
        setSelectedIndex(null)
      }
    }
    
    const handleResultSelection = (selectedResult) => {
      try {
        
        // Check if the selectedResult with the same 'vid' doesn't exist in the selectedResults array
        if (
          !selectedResults?.includes(results[selectedResult]?.visible_keyword) &&
          selectedResult != null &&
          selectedResult != undefined &&
          results[selectedResult] &&
          query
        ) {
          setSelectedResults((prevSelectedResults) => [
            ...prevSelectedResults,
            results[selectedResult]?.visible_keyword,
          ]);
          setQuery(results[selectedResult]?.visible_keyword)
        } else {
          const finalQuery = query.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
          let queryIndex = findObjectIndexInArray(
            results,
            "visible_keyword",
            finalQuery,
            true
          );
          setQuery(results[selectedResult]?.visible_keyword)
          
          if (
            !selectedResults?.includes(results[queryIndex]?.visible_keyword) &&
            queryIndex > -1
          ) {
            // setSelectedResults((prevSelectedResults) => [
            //   ...prevSelectedResults,
            //   results[queryIndex]?.visible_keyword,
            // ])
            // setQuery(results[queryIndex]?.visible_keyword)
          }
        }
        setResults([])
        setSelectedIndex(null)
      } catch (e) {}
    }
  
    const scrollContainerToSelected = () => {
      if (resultsContainerRef?.current && selectedIndex !== null) {
        const selectedRow = resultsContainerRef?.current?.children[selectedIndex]
        if (selectedRow) {
          selectedRow.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }
    }
    const searchAndSelect = async () =>{
      let value = inputRef.current.value;
      let selectedValue = '';
      if(value === '' || value.length <=1){
        return;
      }
      let data = await getVisibleKeywordListingNoRedux({
        search: value,
        page: "1",
        limit: "20",
      });
      data.forEach(element => {
        let visible_keyword = element.visible_keyword.toUpperCase();
        let valueToMatch = value.toUpperCase();
        if(visible_keyword === valueToMatch){
          selectedValue = element.visible_keyword;
        }
      });
      if(!selectedResults?.includes(selectedValue) && selectedValue !== ''){
        setSelectedResults((prevSelectedResults) => [
          ...prevSelectedResults,
          selectedValue,
        ])
      }
    }
    const searchOnEnter = (e) =>{
      if(e.key == 'Enter' && results.length == 0){
        searchAndSelect();
      }
    }
  
  return (
    <div className="row">
      <div className="col-sm">
        <Select label={"Select Classification"} 
          options={categoriesData} labelProps={{style:{
            width: 125
          }}}
          value={selectedCategory}
          onChange={handleCategoryChange}
          mainContainerProps={{
            className: 'mb-3'
          }}
        />
        <Input
          label={"Search Keyword"}
          value={query}
          id="search-input"
          placeholder="Search (min 2 letters)"
          mainContainerProps={{
            className: "d-flex mb-3 position-relative",
          }}
          onChange={(e) => searchKeywords(e)}
          onKeyDown={searchOnEnter}
          ref={inputRef}
          labelProps={{style:{
            width: 125
          }}}
          
          autoComplete="off"
        >
          {!!results?.length && (
            <div
              id="search-results"
              className="p-0"
              style={{
                width: !isMaximized ? 1200-30 : parentDimensions?.width - 20
              }}
              ref={resultsContainerRef}
              tabIndex={0}
            >
              <table className="fixed_headers">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Actual Keywords</th>
                    <th>Keywords</th>
                    <th>Hidden Keywords</th>
                  </tr>
                </thead>
                <tbody id="tablebody-search-container">
                  {results?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        id={item?.vid + item?.visible_keyword + index}
                        // onKeyDown={(e) => handleKeyDown(e, item, index)}
                        onClick={(e) => {
                          handleResultSelection(index)
                          if (inputRef) {
                            inputRef?.current?.focus()
                            setTimeout(function () {
                              inputRef?.current?.select()
                            }, 10)
                          }
                        }}
                        style={{
                          background:
                            selectedIndex === index ? "#b6b6b6a1" : "none",
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{item?.visible_keyword}</td>
                        <td>{item?.visible_keyword}</td>
                        <td style={{wordBreak: 'break-all'}}>
                          {item?.hidden_keywords || ''}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className="text-end p-2" style={{ background: "#31959e54" }}>
                {/* <button
                  type="button"
                  className="btn btn-sm btn-secondary me-2"
                  onClick={() => {}}
                >
                  Insert in Not Found
                </button> */}
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    setResults([])
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </Input>
      </div>
      <div className="col-2">
        <button
          type="submit"
          className="btn btn-sm btn-secondary mb-2"
          onClick={() => {
            setActualKeywords(selectedResults)
          }}
        >
          Submit
        </button>
        <br />
        <button
          type="button"
          className="btn btn-sm btn-secondary mt-2"
          onClick={searchAndSelect}
          // onClick={()=>{
          //   handleResultSelection(selectedIndex)
          // }}
        >
          Search
        </button>
      </div>
    </div>
  )
}

export default SearchSection
