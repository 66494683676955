import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import "./pagination.css";

// Example items, to simulate fetching from another resources.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export default function  PaginatedItems({ itemsPerPage,items,currentPageFunction ,page,handlePageChange}) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [pageVal, setPageVal] = useState('');

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;

  // const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items / itemsPerPage);

  // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % items.length;
  //   console.log(
  //     `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   );
  //   setItemOffset(newOffset);
  // };
  const handlePageClick = (event) => {
    if (pageVal > 0) {
      let val = Math.ceil(pageVal);
      // let newObj={selected: 10,fromPageVal:true}
      // currentPageFunction(newObj);
      // const newOffset = (val * itemsPerPage) % items.length;
      // setItemOffset(newOffset);
      if (event?.isPrevious) {
        if(val>1){
        handlePageChange(val - 1);
        setPageVal(val - 1);
        }
      }
      if(event?.isNext) {
        if(pageCount>page){
          handlePageChange(val + 1);
          setPageVal(val + 1);
        }
       
      }
    }
     else {
      if (event?.isPrevious) {
        if(page>0)
        currentPageFunction({ ...event, fromPageVal: false });
      }
      if (event?.isNext) {
        if(pageCount>page){
        currentPageFunction({ ...event, fromPageVal: false });
        }
      }
      const newOffset = (event.selected * itemsPerPage) % items.length;
      setItemOffset(newOffset);
    }
  };

  return (
    <>
      {/* <Items currentItems={currentItems} /> */}
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onClick={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< prev"
        renderOnZeroPageCount={null}
        className='displayPagination'
        pageClassName='displayLi'
        previousClassName='previousClassName'
        nextClassName='nextClassName'
        activeLinkClassName='activeLinkClassName'
      />
    </>
  );
}

// Add a <div id="container"> to your HTML to see the component rendered.
// ReactDOM.render(
//   <PaginatedItems itemsPerPage={4} />,
//   document.getElementById('container')
// );