import axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const exportImagesApi = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.exportImages}`, payload, {
        responseType: 'arraybuffer'
      });
      if (
        resp?.status >= 200 &&
        resp?.status < 400
      ) {
        return resp?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return false
    }
};

export const insertImages = async (payload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadImage}`, payload, {
      responseType: 'arraybuffer'
    });
    if (resp?.status >= 200 && resp?.status < 400) {
      // toast.success(resp.data.message, {
      //   autoClose: 2500,
      // });
      //showToast('success',data?.data?.message)
      return resp.data;
    } else {
      throw resp;
    }
  } catch (e) {
    // toast.error(errorMessage(e), {
    //   autoClose: 2500,
    // });
  }
};