import React from 'react'
import { TableVirtuoso } from 'react-virtuoso'

function BackupResult({tableData=[], handleTableItemClick=()=>{}, selectedRow}) {
  return (
    <div
      className="p-0 border-1"
      style={{flex: 1}}
      tabIndex={0}
    >
      <TableVirtuoso
        style={{ height: "100%", border: "1px solid lightgray" }}
        totalCount={tableData.length}
        components={{
          Table: ({ style, ...props }) => {
            return (
              <table
                {...props}
                style={{
                  ...style,
                  width: "100%",
                  tableLayout: "fixed",
                  borderCollapse: "collapse",
                  borderSpacing: 0
                }}
              />
            );
          },
          TableHead: (props) =>{
            return (
              <thead 
              {...props}
                style={{
                  ...props?.style,
                  zIndex: 0
                }}
              >
                {props?.children}
              </thead>
            );
          },
          TableRow: (props) => {
            const index = props["data-index"];
            const row = tableData[index];
            return (
              <tr 
              {...props}
              onMouseDown={(e) => {
                handleTableItemClick({...row, currentIndex: index})
              }}
              style={{
                ...props?.style,
                background: selectedRow?.keyword_id === row?.keyword_id ? "#b6b6b6a1" : "none",
                cursor: 'pointer'
              }}
              >
                <td style={{ background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white',  padding: 4  }}>{row?.image_keyword_id}</td>
                <td style={{  fontSize: 12,  padding: 4 ,textAlign: "center" }}><div className='truncate-string'>{row?.image_name}</div></td>
                <td style={{  fontSize: 12,  padding: 4,wordBreak: 'break-all',  }}>{row?.keywords}</td>
                {/* <td style={{  fontSize: 12,   padding: 4, wordBreak: 'break-all',textAlign: "center"}}>
                  {row?.image_id || ''}
                </td> */}
                <td style={{  fontSize: 12,  padding: 4,textAlign: "center"  }}>{row?.shoot_id}</td>

              </tr>
            );
          }
        }}
        fixedHeaderContent={(index, user) => (
          <tr style={{ background: "lightgray", margin: 0 }}>
            <th style={{ width: 80, background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white', padding: 4 }}>Keyword ID</th>
            <th style={{ width: 110, background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white', padding: 4}}>Image Name</th>
            <th style={{ width: 200, background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white', padding: 4}}>keywords</th>
            {/* <th style={{ width: 50, background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white', padding: 4}}>Image ID</th> */}
            <th style={{ width: 50, background: '#31959ea1', fontWeight: "bold", fontSize: 12, textAlign: "center", color: 'white', padding: 4}}>Shoot ID</th>

          </tr>
        )}
      />
    </div>
  )
}

export default BackupResult