import React from "react"
import "./App.css"
import Navbar from "./components/Navbar"
import ImageKeywording from "./screens/ImageKeywording"
import { useAppContext } from "./context/AppContext"
import ExportXls from "./screens/ExportXls"
import SearchScreen from "./screens/SearchScreen"
import EnterNewKeyword from "./screens/EnterNewKeyword"
import RelationshipScreen from './screens/RelationshipScreen';
import RulesDefineScreen from "./screens/RulesDefineScreen"
import ImageBackup from "./screens/ImageBackup"
import DownloadBackup from "./screens/DownloadBackup"

function App() {
  const { menu, bringToFront} = useAppContext()
  return (
    <div className="main-container d-flex flex-column">
      <header className="nav-area">
        <Navbar />
      </header>
      <div className="position-relative" style={{flex: 1}}>
        {menu?.map((item, index) => {
          return item.visible && item?.id === 1 ? (
            <ExportXls itemContainer={item} containerIndex={index} bringToFront={bringToFront} />
          ) : item.visible && item?.id === 2 ? (
            <RelationshipScreen itemContainer={item} containerIndex={index} bringToFront={bringToFront} />
          ) : item.visible && item?.id === 3 ? (
            <ImageKeywording itemContainer={item} containerIndex={index} bringToFront={bringToFront}/>
          ) : item.visible && item?.id === 4 ? (
            <SearchScreen itemContainer={item} containerIndex={index} bringToFront={bringToFront}/>
          ) : item.visible && item?.id === 5 ? (
            <EnterNewKeyword itemContainer={item} containerIndex={index} bringToFront={bringToFront}/>
          ) : item.visible && item?.id === 6 ? (
            <RulesDefineScreen itemContainer={item} containerIndex={index} bringToFront={bringToFront}/>
          ) 
          // :
          //  item?.visible && item?.id===7?(
          //   <ImageBackup itemContainer={item} containerIndex={index} bringToFront={bringToFront}/>
          // )
          
          :item?.visible && item?.id===7?(
            <DownloadBackup itemContainer={item} containerIndex={index} bringToFront={bringToFront}/>
            )
        
          :null
        })}
        <div id="imagePreviewWindowContainer" style={{width:'100%',height:'100%'}}></div>
      </div>
    </div>
  )
}

export default App
