import React, { useState, useRef, useEffect } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import Input from "../components/CommonComp/Input"
import Select from '../components/CommonComp/Select';
import { exportImagesApi, insertImages } from "../services/ExportDataApis"
import { computeLoaderState, downloadBlobXLSFile, handleParse, processCSV, showToast } from "../lib/helper"

const options = [
  {label: 'Folder No.', value: 'shoot_id'},
  {label: 'Image Name', value: 'image_id'},
]

function ExportXls({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext()
  const [loading, setLoading] = useState({
    submit: false
  });
  return (
    <WindowCreater
      title={itemContainer?.title}
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(1, false)
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
    >
      {<ChildComponent loading={loading} setLoading={setLoading} />}
    </WindowCreater>
  )
}

export default ExportXls

const ChildComponent = ({isMaximized, loading, setLoading}) => {
  const [selectedFolder, setSelectedFolder] = useState({label: 'Folder No.', value: 'shoot_id'});
  const [fileValue,setFileValue]=useState(null);
  var csvFileData = [
    ['folderId' ],
    ["101" ],
    ["102"]
  ];
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const { handleMenu } = useAppContext()
  const handleFolderChange = (e) => {
    setSelectedFolder(e)
  }

  const handleSubmit = async (e) => {
    if(selectedFolder && from && to){
      setLoading({
        ...loading,
        submit: true
      })
      let resp = await exportImagesApi({
        type: selectedFolder?.value,
        from: from,
        to: to,
      })
      if(resp){
        downloadBlobXLSFile(resp, selectedFolder?.value + "-" + from + '-' + to)
      }
      setLoading({
        ...loading,
        submit: false
      })
    }
  }

  function download_csv_file() {
    //define the heading for each row of the data
    var csv = '';
    //merge the data with CSV
    csvFileData.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    //provide the name for the CSV file to be downloaded
    hiddenElement.download = 'Sample.csv';
    hiddenElement.click();
  }

  const handleFileValue=(e)=>{
    setFileValue(e.target.files[0])
  }

  const checkCsvHeader = (data) => {
    let isValid = false;
    let validHeader = [
      'folderId'
    ];
    if (data?.length == validHeader?.length) {
      validHeader.every((item, i) => {
        if (item != data[i]) {
          isValid = false;
          return false;
        }
        if (validHeader.length == i + 1) {
          isValid = true;
        }
        // Make sure you return true. If you don't return a value, `every()` will stop.
        return true;
      });
    }
    return isValid;
  };

  const handleFileUpload = async () => {
    try {
      let csvText = await handleParse(fileValue);
      let csvHeaderArray = processCSV(csvText);
      if (csvHeaderArray?.length) {
        if (checkCsvHeader(csvHeaderArray)) {
          setLoading({
            ...loading,
            submit: true
          })
          const formData = new FormData();
          formData.append("uploaded_file", fileValue);
          let data = await insertImages(formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          if (data) {
              downloadBlobXLSFile(data,'keywordList' )
              setLoading({
                ...loading,
                submit: false
              })
            
           
          }
        } else {
          // toast.error("Maybe your CSV headers format is incorrect. Please check and try again.", {
          //   autoClose: 3000
          // })
          showToast('error',"Maybe your CSV headers format is incorrect. Please check and try again.")

        }
      }
      // setLoading(false);
    } catch (e) {
      // setLoading(false);
      console.log(e, 'error');
    }
  };
  
  return (
    <div className="container-child-wrapper">
        <div className="row">
          <div className="col-sm-4">
            <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>Search Type</div>
            <div>
              <Select 
                  options={options} 
                  mainContainerProps={{
                      className: 'd-flex m-0 ms-1'
                  }}
                  value={selectedFolder}
                  onChange={handleFolderChange}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>From</div>
            <div>
              <Input 
                value={from}
                onChange={(e)=>{
                    setFrom(e.target.value)
                }}
                onBlur={()=>{
                  let str = from.replace(/\s+/g,' ').trim();
                  setFrom(str)
                }}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>To</div>
            <div>
              <Input 
                value={to}
                onChange={(e)=>{
                    setTo(e.target.value)
                }}
                onBlur={()=>{
                  let str = to.replace(/\s+/g,' ').trim();
                  setTo(str)
                }}
              />
            </div>
          </div>
          <div className="col-sm-12 mt-2 mb-3 d-flex  justify-content-end">
            {/* <div className='row'>
             
              <div className='col-4 d-flex justify-content-end'>
              <button
              className="btn btn-sm btn-secondary me-2"
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
            </button>
            <br />
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => {
                handleMenu(1, false)
              }}
            >
              Exit
            </button>
              </div>
            </div> */}
            <button
              className="btn btn-sm btn-secondary me-2"
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
            </button>
            <br />
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => {
                handleMenu(1, false)
              }}
            >
              Exit
            </button>

          </div>
          <hr/>
          
          <div className='row my-2 g-2 h-100'>
            <div className='col-12 d-flex justify-content-start'>
              <button
              className="btn btn-sm btn-secondary me-2"
              onClick={() => {
                download_csv_file()
              }}
            >
              Download Sample
            </button>
            <br />
            <button
              className="btn btn-sm btn-secondary me-2">
              <label for="fileSelect"></label>
              <input id="fileSelect" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e)=> handleFileValue(e)} />
            </button>
            <br/>
            <button
              className="btn btn-sm btn-secondary me-2"
              onClick={()=>handleFileUpload()} >
               Upload CSV
            </button>
            </div>
          </div>
        </div>
    </div>
  )
}
