import React, { useState, useEffect } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import Input from "../components/CommonComp/Input"
import { computeLoaderState, findObjectInArray, showToast } from "../lib/helper"
import ConfirmDialog from "../components/CommonComp/modals/ConfirmDialog";
import MessageDialog from "../components/CommonComp/modals/MessageDialog"
import BackupResult from "../components/BackupImages/BackupResult"
import { getBackupImageListing, getBackupKeywords } from "../services/backupImage"
import PaginatedItems from "./pagination"
function ImageBackup({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext()
  const [loading, setLoading] = useState({
    submit: false
  });

  return (
    <WindowCreater
      title={itemContainer?.title}
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(7, false)
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
    >
      {<ChildComponent handleMenu={handleMenu} loading={loading} setLoading={setLoading} />}
    </WindowCreater>
  )
}

export default ImageBackup

const ChildComponent = ({ isMaximized, handleMenu = () => { }, loading, setLoading }) => {
  const [query, setQuery] = useState("");
  const limit=20;
  const [page,setPage]=useState(1);
  const [tableData, setTableData] = useState([])
  const [enteredKeyword, setEnteredKeyword] = useState('')
  const [hiddenKeyword, setHiddenKeyword] = useState('')
  const [selectedRow, setSelectedRow] = useState([])
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [totalItems,setTotalItems]=useState(0);
  const [messageModal, setMessageModal] = useState({
    messageHeading: '',
    open: false,
    message: ''
  })

  async function fetchBackupKeyword(page) {
    setLoading({
      ...loading,
      submit: true
    })
    let payload={page:page,limit:limit}
    let data = await getBackupImageListing(payload)
    if (data) {
      setTableData(data?.rows);
      setTotalItems(data?.count)
    }
    setLoading({
      ...loading,
      submit: false
    })
  }

  const currentPageFunction=(param)=>{
    if(param?.fromPageVal){
     // GetSearchResults(param?.selected+1);
    }
    else{
    if(param?.isPrevious){
      if(page>1){
      setPage(page-1)
      fetchBackupKeyword(page-1);
      }
    }
    if(param?.isNext){
      fetchBackupKeyword(page+1);
      setPage(page+1)
    }
  }
   }

   const handleBackupKeywords=async()=>{
    let data = await getBackupKeywords();
    if(data?.status==200){
      showToast('success',data?.data?.message)
    } 
    setAddModalOpen(false);
    fetchBackupKeyword(page)
   }

 const handlePageChange=()=>{
 
 }


  useEffect(() => {
    fetchBackupKeyword(page)
  }, [page])
  
  useEffect(() => {
    fetchBackupKeyword(page)
  }, [])

  const takingBackupConfirmPopUp=()=>{
   setAddModalOpen(true)
  }
 
 
  return (
    <div className="container-child-wrapper" style={{ display: "flex" }}>
      <div className=" d-flex flex-column" style={{ flex: "1" }}>
        <div className="container mb-2">
          <div className="row border-1 px-2 py-2">
            <div className="col-12 text-end">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  takingBackupConfirmPopUp()
                }}
              >
                Backup
              </button>
            </div>
          </div>
        </div>
        <BackupResult tableData={tableData} selectedRow={selectedRow} />
        <div py={3
        }
        style={{   'padding-top': '4px',
                    'padding-bottom':'2px'
        }}>
        <PaginatedItems   items={totalItems}  handlePageChange={handlePageChange} page={page} currentPageFunction={currentPageFunction} itemsPerPage={limit}/>
        </div>
      </div>
      {
        <>
          <ConfirmDialog open={addModalOpen} body={'Do you surely want to take image keywords backup?'} handleSuccess={() => {
            handleBackupKeywords()
          }}
            handleClose={() => {
              setAddModalOpen(false);
            }} />
       
        </>
      }
    </div>
  )
}
