import React, { useState, useEffect } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import DynamicScrollContent from "../components/HOC/DynamicScrollContent"
import { createKeywordApi, deleteKeywordApi, editKeywordApi, getKeywordsCateogyListing } from "../services/EnterNewKeyword"
import { getCategories } from "../services/keywordsApis"
import SearchTableResult from "../components/EnterNewKeywordScreen/SearchTableResult"
import ClassificationSection from "../components/EnterNewKeywordScreen/ClassificationSection"
import RelateWithActualKeyword from "../components/EnterNewKeywordScreen/RelateWithActualKeyword"
import Input from "../components/CommonComp/Input"
import { computeLoaderState, downloadBlobXLSFile, findObjectInArray, handleParse, processCSV, showToast } from "../lib/helper"
import ConfirmDialog from "../components/CommonComp/modals/ConfirmDialog";
import MessageDialog from "../components/CommonComp/modals/MessageDialog"
import { getBackupKeywords, getBackupShoots, getShoots } from "../services/backupImage"
import DownloadBackUps from "../components/DownloadBackUp/DownloadBackUps"
import SearchSection from "../components/DownloadBackUp/SearchSection"
import PaginatedItems from "./pagination"
import { insertImages } from "../services/ExportDataApis"
function DownloadBackup({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext();
  const [loading, setLoading] = useState({
    submit: false
  });

  return (
    <WindowCreater
      title={itemContainer?.title}
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(8, false)
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
    >
      {<ChildComponent handleMenu={handleMenu} loading={loading} setLoading={setLoading} />}
    </WindowCreater>
  )
}

export default DownloadBackup

const ChildComponent = ({ isMaximized, handleMenu = () => { }, loading, setLoading }) => {
  const [query, setQuery] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [tableData, setTableData] = useState([]);
  const limit = 4;
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedRow, setSelectedRow] = useState([])
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [messageModal, setMessageModal] = useState({
    messageHeading: '',
    open: false,
    message: ''
  });

  const currentPageFunction = (param) => {
    if (param?.fromPageVal) {
      // GetSearchResults(param?.selected+1);
    }
    else {
      if (param?.isPrevious) {
        if (page > 1) {
          setPage(page - 1)
          fetchBackupKeyword(page - 1);
        }
      }
      if (param?.isNext) {
        fetchBackupKeyword(page + 1);
        setPage(page + 1)
      }
    }
  }

  const handlePageChange = () => {

  }


  useEffect(() => {
    fetchShoots(page)
  }, [page])


  async function fetchShoots(page) {
    setLoading({
      ...loading,
      submit: true
    })
    let data = await getShoots({ page: page, limit: limit })
    if (data?.length) {

      let newData = data?.map((el) => {
        return {
          label: el?.shoot_id,
          value: el?.shoot_id,
          isChecked: false,
          shoot_id: el?.shoot_id
        }
      })
      setCategoriesData(newData);
      setTableData(newData);
      setTotalItems(data?.count)
    }
    setLoading({
      ...loading,
      submit: false
    })
  }

  useEffect(() => {
    fetchShoots(1)
  }, [])

  const handleCategoryChange = (data) => {
    setSelectedCategory(data)
  }
  const handleTableItemClick = (data) => {
    let items = [...selectedRow];
    let index = items?.findIndex((item) => item?.shoot_id == data?.shoot_id);
    if (index < 0 || items?.length == 0) {
      items?.push(data);
    }
    else {
      items?.splice(index, 1)
    }
    setSelectedRow(items)
  }
  const takingBackupConfirmPopUp = () => {
    if (selectedRow?.length)
      setAddModalOpen(true)
    else {
      showToast('error', "Nothing to Take Backup!")
    }
  }

  const [fileValue,setFileValue]=useState(null);
  var csvFileData = [
    ['folderId' ],
    ["101" ],
    ["102"]
  ];

  const handleBackupKeywords = async () => {
    let shootsIds = selectedRow?.map((item) => item?.shoot_id);
    if (shootsIds?.length) {
      let list = shootsIds?.join(",");
      let payload = { shoot_id: list }
      let data = await getBackupShoots(payload);
      if (data) {
        downloadBlobXLSFile(data, 'ShootsBackup');
        setSelectedRow([]);
      }
      // if(data?.status==200){
      //   showToast('success',data?.data?.message)
      // } 
      setAddModalOpen(false);
    }
    showToast('Error', "Nothing to Take Backup!")
    setAddModalOpen(false);
    // fetchBackupKeyword(page)
  }

  function download_csv_file() {
    //define the heading for each row of the data
    var csv = '';
    //merge the data with CSV
    csvFileData.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    //provide the name for the CSV file to be downloaded
    hiddenElement.download = 'Sample.csv';
    hiddenElement.click();
  }

  const handleFileValue=(e)=>{
    setFileValue(e.target.files[0])
  }

  const checkCsvHeader = (data) => {
    let isValid = false;
    let validHeader = [
      'folderId'
    ];
    if (data?.length == validHeader?.length) {
      validHeader.every((item, i) => {
        if (item != data[i]) {
          isValid = false;
          return false;
        }
        if (validHeader.length == i + 1) {
          isValid = true;
        }
        // Make sure you return true. If you don't return a value, `every()` will stop.
        return true;
      });
    }
    return isValid;
  };

  const handleFileUpload = async () => {
    try {
      let csvText = await handleParse(fileValue);
      let csvHeaderArray = processCSV(csvText);
      if (csvHeaderArray?.length) {
        if (checkCsvHeader(csvHeaderArray)) {
          setLoading({
            ...loading,
            submit: true
          })
          const formData = new FormData();
          formData.append("uploaded_file", fileValue);
          let data = await insertImages(formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          if (data) {
              downloadBlobXLSFile(data,'keywordList' )
              setLoading({
                ...loading,
                submit: false
              })
            
           
          }
        } else {
          // toast.error("Maybe your CSV headers format is incorrect. Please check and try again.", {
          //   autoClose: 3000
          // })
          showToast('error',"Maybe your CSV headers format is incorrect. Please check and try again.")

        }
      }
      // setLoading(false);
    } catch (e) {
      // setLoading(false);
      console.log(e, 'error');
    }
  };

  return (
    <div className="container-child-wrapper">
      {/* <div className=" d-flex flex-column" style={{ flex: "1" }}>
        <div className="container mb-2">
          <div className="row border-1 px-2 py-2">
            <div className="col-12 text-end">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  takingBackupConfirmPopUp()
                }}
              >
                Backup
              </button>
            </div>
          </div>
        </div>
        <DownloadBackUps tableData={tableData}
          handleTableItemClick={handleTableItemClick}
          selectedRow={selectedRow} />
      </div> */}
       <div className="row">

          <div className='row my-2 g-2 h-100'>
            <div className='col-12 d-flex justify-content-start'>
              <button
              className="btn btn-sm btn-secondary me-2"
              onClick={() => {
                download_csv_file()
              }}
            >
              Download Sample
            </button>
            <br />
            <button
              className="btn btn-sm btn-secondary me-2">
              <label for="fileSelect"></label>
              <input id="fileSelect" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e)=> handleFileValue(e)} />
            </button>
            <br/>
            <button
              className="btn btn-sm btn-secondary me-2"
              onClick={()=>handleFileUpload()} >
               Upload CSV
            </button>
            </div>
          </div>
        </div>
      

      {
        <>
          <ConfirmDialog open={addModalOpen} body={'Do you surely want to take image keywords backup?'} handleSuccess={() => {
            handleBackupKeywords()
          }}
            handleClose={() => {
              setAddModalOpen(false);
            }} />

        </>
      }
    </div>
  )
}
