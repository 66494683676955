import axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const getBackupImageListing = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.backupImage}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};
export const getBackupKeywords = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.backupKeywords}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp;
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const getBackupShoots = async (payload={}) => {
  try {
    const resp = await axios.post(`${ApiConfig.downloadBackUp}`, payload,{
      responseType: 'arraybuffer'
    });
    if (
      resp?.status >= 200 &&
      resp?.status < 400
    ) {
      return resp?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    return []
  }
};

export const getShoots = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.shootsListing}`,payload);
    if (
      resp?.data?.status >= 200 &&
      resp?.data?.status < 400
    ) {
      return resp?.data?.data
    } else {
      throw resp;
    }
  } catch (e) {
    return []
  }
};

